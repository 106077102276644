<!-- 
	This is an empty page for layout demonstrations, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div class="mb-24">
		<div class="bg-white border-gray-3" style="height: calc(100vh - 89px); border: 1px solid">
            <p>
                This feature is coming soon. We are working hard to bring you this feature as soon as possible.
            </p>

            <p>
                Please check back later. 
            </p>
            
		</div>
	</div>
</template>

<script>

	export default ({
		data() {
			return {
			}
		},
	})

</script>

<style lang="scss">
</style>